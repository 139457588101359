html, body {
  height: 100%;
  background: #52768e;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  color: #607D8B;
  display: table;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
}

.container {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

a {
  text-decoration: none;
}

.content {
  text-align: center;
  display: inline-block;
}

.error {
  color: #eee;
}

.error .title {
  padding: 10px;
  font-size: 1.8em;
  background: rgba(154, 154, 154, 0.5);
  *background: #9a9a9a;
  border-radius: 8px;
}

.error .urls a {
  color: #eee;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.error .urls a:hover {
  color: #000;
}

.links {
  text-align: left;
  background: rgba(154, 154, 154, 0.5);
  *background: #9a9a9a;
  padding: 10px;
  line-height: 24px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 18px;
  *color: #000;
}

.links:hover {
  background: rgba(228, 228, 228, 0.7);
  *background: #e4e4e4;
  color: #9c9c9c;
  *color: #9c9c9c;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.links a {
  color: #eee;
  *font-weight: bold;
  *color: #000;
}

.links:hover a {
  color: #000;
}

.links a i {
  font-size: 18px;
}

.home-color-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #003973;
  /* Old browsers */
  background: -moz-linear-gradient(left, #003973 0%, #e5e5be 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #003973 0%, #e5e5be 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #003973 0%, #e5e5be 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003973', endColorstr='#e5e5be', GradientType=0);
  /* IE6-9 */
}

.home-box {
  font-family: Garamond, Times, Georgia, serif;
  -webkit-user-select: none;
  position: absolute;
  z-index: 10;
  width: 440px;
  height: 240px;
  background: rgba(97, 97, 97, 0.3);
  left: 50%;
  top: 50%;
  margin-left: -220px;
  margin-top: -120px;
  padding: 20px;
  border-radius: 12px;
  color: #eee;
}

.home-box h2, .home-box h3 {
  color: #eee;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  *font-weight: bold;
  margin: 0;
  padding: 0;
}

.home-box h2 {
  display: block;
  font-size: 1.5em;
  -webkit-margin-before: 0.83em;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.home-box h2 img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  vertical-align: middle;
  margin-left: 5px;
}

.home-box h3 {
  margin-bottom: 30px;
  display: block;
  font-size: 1.17em;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

.home-box .links {
  text-align: left;
  background: rgba(154, 154, 154, 0.5);
  *background: #9a9a9a;
  padding: 10px;
  line-height: 24px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 18px;
  *color: #000;
}

.home-box .links:hover {
  background: rgba(228, 228, 228, 0.7);
  *background: #e4e4e4;
  color: #9c9c9c;
  *color: #9c9c9c;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.home-box .links a {
  color: #eee;
  *font-weight: bold;
  *color: #000;
}

.home-box .links:hover a {
  color: #000;
}

.home-box .links a i {
  font-size: 18px;
}

@media screen and (max-width: 481px) {
  .home-box {
    width: 310px;
    margin-left: -175px;
  }
}

@media screen and (max-width: 361px) {
  .home-box {
    width: 260px;
    margin-left: -150px;
  }
  .home-box h3 {
    margin-bottom: 20px;
  }
  .home-box .links {
    line-height: 18px;
    margin-bottom: 10px;
  }
  .home-box h2 img {
    margin-top: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
